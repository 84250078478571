module.exports = [{
      plugin: require('/Users/brant/Workspace/cinesend-trailers-website/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/brant/Workspace/cinesend-trailers-website/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#ffffff","showSpinner":false,"minimum":0.1},
    },{
      plugin: require('/Users/brant/Workspace/cinesend-trailers-website/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cinesend.com"},
    },{
      plugin: require('/Users/brant/Workspace/cinesend-trailers-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-96987216-4"},
    },{
      plugin: require('/Users/brant/Workspace/cinesend-trailers-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
